.main-nav {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: fixed;
  top: 20px;
  right: 20px;
  list-style: none;
  z-index: 500;

  li + li {
    padding-left: 10px;
  }

  li {
    font-size: 16px;

    a {
      font-weight: normal;
      color: #fff;
      text-decoration: none;
    }
  }

  li:last-child {
    a {
      border-bottom: 1px solid #fff;
    }
  }

  li a.active {
    opacity: 0.7;
  }

  li.active-building a,
  li a.button.active {
    background-color: #fff;
    color: #000;
    opacity: 1;
  }
}
