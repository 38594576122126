@import './assets/colors';
@import './assets/layout';
@import './assets/global.scss';

:root {
  --color-forsale: $color-forsale;
  --color-sold: $color-sold;
  --color-reserved: $color-reserved;
}

body {
  background-color: #000;
  color: #fff;
  font-size: 14px;
}

.main-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  height: 100vh;

  @media (max-width: $bp-medium) {
    grid-template-columns: 1fr;
    grid-template-rows: 50vh 50vh;
  }
}
