@import '../assets/colors';
@import '../assets/layout';

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  background: transparent;
  text-align: center;

  p {
    margin: 1em 0;
  }
}

.open-view-nav {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 500;
}

.close-view-nav {
  background-color: $color-white;
  border: 1px solid transparent;
  border-radius: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $color-black;
  padding: 0;
  top: -0.5em;
  right: -0.5em;
  left: auto;
  position: absolute;
  text-align: center;
  line-height: 1;
  transition: 300ms ease all;
  height: 1.75em;
  width: 1.75em;

  &:hover {
    background-color: $color-black;
    border-color: $color-white;
    color: $color-white;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 14px;
  }
}

.birdview {
  background-color: #000;
  position: relative;
  width: 500px;
  padding: 80px;
  z-index: 500;

  img {
    height: auto;
    width: 100%;
  }

  @media screen and (max-width: $bp-medium) {
    width: 400px;
    padding: 60px;
  }

  @media screen and (max-width: $bp-small) {
    width: 320px;
    padding: 40px;
  }
}

img.camera-icon {
  display: block;
  height: 2.4em;
  width: 2.4em;
}

.scene-menu {
  margin: 0;
  padding: 0;
  list-style: none;

  a {
    background: url('../assets/images/camera-icon.svg') no-repeat center center;
    background-size: 32px 32px;
    display: block;
    height: 32px;
    width: 32px;

    @media screen and (max-width: $bp-medium) {
      background-size: 28px 28px;
      height: 28px;
      width: 28px;
    }

    @media screen and (max-width: $bp-small) {
      background-size: 24px 24px;
      height: 24px;
      width: 24px;
    }
  }

  a:hover {
    opacity: 0.5;

    @media screen and (max-width: $bp-small) {
      opacity: 1;
    }
  }

  a.active {
    background-image: url('../assets/images/camera-icon-active.svg');
  }

  li:nth-child(1) {
    position: absolute;
    right: 40px;
    bottom: 40px;

    @media screen and (max-width: $bp-medium) {
      right: 20px;
      bottom: 20px;
    }
  }

  li:nth-child(2) {
    position: absolute;
    right: 40px;
    top: 40px;

    @media screen and (max-width: $bp-medium) {
      right: 20px;
      top: 20px;
    }
  }

  li:nth-child(3) {
    position: absolute;
    left: 40px;
    top: 40px;

    @media screen and (max-width: $bp-medium) {
      left: 20px;
      top: 20px;
    }
  }

  li:nth-child(4) {
    position: absolute;
    left: 40px;
    bottom: 40px;

    @media screen and (max-width: $bp-medium) {
      left: 20px;
      bottom: 20px;
    }
  }
}
