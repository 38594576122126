.loading-wrapper {
  background-color: #000;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.loading {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;

  div {
    position: absolute;
    background: #fff;
    opacity: 1;
    border-radius: 50%;
    animation: loading 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    height: 32px;
    width: 32px;
  }

  div:nth-child(2) {
    animation-delay: -1s;
  }
}

@keyframes loading {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
