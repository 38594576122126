@import '../assets/colors';
@import '../assets/layout';

.tippy-content {
  background-color: $color-black;
  color: $color-white;
  font-size: 16px;
  padding: 15px;

  h3 {
    margin-bottom: 5px;
  }

  strong {
    font-weight: normal;
  }

  @media screen and (max-width: $bp-medium) {
    font-size: 12px;
    padding: 10px;
  }
}

.tippy-arrow {
  color: $color-black;
}
