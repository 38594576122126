@import './fonts';
@import './layout';

@font-face {
  font-family: 'MetricWeb';
  src: url('./fonts/MetricWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MetricWeb-semibold';
  src: url('./fonts/MetricWeb-Semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: $font-regular;
  font-weight: normal;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-semibold;
  font-size: 24px;
  font-weight: normal;
  margin: 0;

  @media screen and (max-width: $bp-medium) {
    font-size: 20px;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 16px;
  }
}

p {
  margin: 0;
}

strong {
  font-family: $font-semibold;
  font-weight: normal;
}

figure,
ul,
ol {
  padding: 0;
  margin: 0;
}

.button,
button,
a.button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 0;
  color: #fff;
  font-size: 16px;
  font-family: $font-semibold;
  font-weight: normal;
  display: inline-block;
  padding: 0.25em 0.5em;
  text-align: center;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: #fff;
    color: #000;
    cursor: pointer;
  }

  @media screen and (max-width: $bp-medium) {
    font-size: 14px;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 12px;
  }
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
