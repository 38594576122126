@import '../assets/fonts';
@import '../assets/colors';
@import '../assets/layout';

.Buildings {
  max-width: 1600px;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  position: relative;

  @media screen and (max-width: $bp-medium) {
    padding: 40px 0 0 0;
    height: 50vh;
  }

  svg {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    @media screen and (max-width: $bp-medium) {
      padding: 40px 0 0 0;
    }

    polygon,
    rect,
    path {
      fill: rgba($color-blue, 0);
      stroke: rgba($color-blue, 0);
      stroke-width: 1px;
      opacity: 1;
      transition: 300ms ease all;
    }

    a.active path,
    path:hover {
      fill: rgba($color-blue, 0.33);
      stroke: rgba($color-blue, 1);
      stroke-width: 2px;
    }
  }

  img {
    display: block;
    height: 100%;
    margin: 0 auto;
    width: auto;
  }
}

.buildings-helper {
  display: flex;
  flex: column nowrap;
  align-items: center;
  padding-left: 40px;
  line-height: 1;

  @media screen and (max-width: $bp-medium) {
    align-items: flex-start;
    padding-top: 40px;
    padding-left: 0;
  }

  h2 {
    font-family: $font-regular;
    font-size: 7.5vmax;
    font-weight: 400;
    margin: 0;
    text-align: left;

    @media screen and (max-width: $bp-medium) {
      text-align: center;
      width: 100%;
    }

    a {
      color: #fff;
      display: block;
      text-decoration: none;
      opacity: 1;

      &.active {
        color: $color-blue;
      }
    }
  }
}
