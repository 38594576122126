@import '../assets/layout';
@import '../assets/colors';

.table-wrapper {
  background-color: $color-blue;
  color: #000;
  padding: 0 20px;
  position: relative;
  overflow: hidden;
}

.table-header {
  background-color: rgba($color-blue, 0.9);
  padding-bottom: 20px;
  position: relative;

  h2 {
    padding-top: 80px;
    text-align: center;
  }

  @media screen and (max-width: $bp-medium) {
    h2 {
      padding-top: 80px;

      .main-wrapper & {
        padding-top: 20px;
      }
    }
  }
}

.before-objects-table-helper {
  margin-top: 20px;
  text-align: center;

  @media screen and (max-width: $bp-small) {
    margin-top: 10px;
  }

  button {
    border-color: #000;
    color: #000;
    margin: 0 auto;

    &:hover {
      background-color: #fff;
      border-color: #fff;
    }
  }
}

.table-container {
  height: 100vh;
  overflow: scroll;
  position: relative;
}

table {
  border-collapse: collapse;
  margin: 0 auto 80px auto;
  max-width: 1680px;
  width: 100%;
}

th {
  border-bottom: 2px solid #000;
  font-weight: bold;

  button {
    background-color: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
  }
}

tbody tr:hover {
  background-color: #000;
  color: #fff;
}

tbody tr:hover a {
  color: #fff;
}

td {
  border-bottom: 1px solid #000;
}

th {
  padding: 10px;

  svg {
    font-size: 0.75em;
    margin-left: 0.334em;
  }

  @media screen and (max-width: $bp-small) {
    padding: 5px;
  }
}

th,
td {
  white-space: nowrap;

  .active & {
    background-color: #000;
    color: #fff;
  }

  .active & a {
    color: #fff;
  }
}

tr,
td,
th {
  font-size: 16px;
  vertical-align: top;
  text-align: left;

  a {
    display: block;
    padding: 10px;
    color: #000;
    text-decoration: none;

    @media screen and (max-width: $bp-small) {
      padding: 5px;
    }
  }

  @media screen and (max-width: $bp-medium) {
    font-size: 12px;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 11px;
  }
}

th {
  font-size: 16px;
  font-weight: normal;

  @media screen and (max-width: $bp-medium) {
    font-size: 14px;
  }

  @media screen and (max-width: $bp-small) {
    font-size: 12px;
  }

  button {
    color: #000;
    font-size: 16px;
    font-weight: normal;

    &:hover {
      background-color: transparent;
      color: #000;
      opacity: 0.7;
    }

    @media screen and (max-width: $bp-medium) {
      font-size: 14px;
    }

    @media screen and (max-width: $bp-small) {
      font-size: 12px;
    }
  }
}

.building-cell a {
  text-transform: capitalize;
}

.objects-in-render-info {
  background: linear-gradient(transparent, $color-blue 40%);
  font-size: 13px;
  margin: 0;
  padding: 40px 20px 20px 20px;
  position: absolute;
  bottom: 0;
  text-align: center;
  left: 0;
  width: 100%;

  @media screen and (max-width: $bp-small) {
    background-color: $color-blue;
    padding: 10px 20px 10px 20px;
  }

  > * + * {
    margin-left: 20px;
  }

  a {
    border-bottom: 1px solid #000;
    color: #000;
    font-size: 16px;
    text-decoration: none;

    @media screen and (max-width: $bp-medium) {
      font-size: 14px;
    }

    @media screen and (max-width: $bp-small) {
      font-size: 12px;
    }
  }
}
