@import '../assets/colors';
@import '../assets/layout';

.scene-container {
  position: relative;
}

.Scene {
  max-width: 1600px;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  position: relative;

  img {
    display: block;
    height: 100%;
    margin: 0 auto;
    width: auto;
  }

  @media screen and (max-width: $bp-medium) {
    padding: 40px 0 20px 0;
    height: 50vh;
  }
}

.scene-svg {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;

  @media screen and (max-width: $bp-medium) {
    padding: 40px 0 20px 0;
  }

  polygon,
  rect,
  path {
    fill: rgba(100, 100, 100, 0);
    stroke: transparent;
    stroke-width: 1px;
    opacity: 1;
    transition: 300ms ease all;

    &:hover,
    &.active {
      stroke: #fff;
    }
  }

  .reserved {
    fill: var($color-reserved, 0);

    &.active,
    &:hover,
    .show-status-for-objects & {
      fill: rgba($color-reserved, 0.7);
      stroke: #fff;
    }
  }

  .sold-not-admitted {
    fill: rgba($color-reserved, 0);

    &.active,
    &:hover,
    .show-status-for-objects & {
      fill: rgba($color-reserved, 0.7);
      stroke: #fff;
    }
  }

  .sold {
    fill: rgba($color-sold, 0);

    &.active,
    &:hover,
    .show-status-for-objects & {
      fill: rgba($color-sold, 0.7);
      stroke: #fff;
    }
  }

  .for-sale {
    fill: rgba($color-forsale, 0);

    &.active,
    &:hover,
    .show-status-for-objects & {
      fill: rgba($color-forsale, 0.7);
      stroke: #fff;
    }
  }
}

.toggle-status-button {
  position: fixed;
  top: 20px;
  left: 92px;
  z-index: 250;

  @media screen and (max-width: $bp-medium) {
    left: 82px;
  }

  @media screen and (max-width: $bp-small) {
    left: 76px;
  }
}
.prev-scene,
.next-scene {
  background: transparent;
  border: none;
  color: $color-white;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  padding: 0;
  position: absolute;
  top: 50%;

  &:hover {
    background-color: transparent;
    color: $color-white;

    svg {
      opacity: 0.5;
    }
  }
}

.prev-scene {
  left: 20px;
}

.next-scene {
  right: 20px;
}
