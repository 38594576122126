.button-goback {
  border: none;
  border-bottom: 1px solid #fff;
  padding: 0;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 500;

  &:hover {
    background-color: transparent;
    color: #fff;
  }
}
