@import '../assets/colors';
@import '../assets/layout';

.SingleObject {
  background-color: $color-black;
  color: $color-white;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 40px;
  padding: 80px 20px 20px 20px;
  margin: 0 auto;
  align-items: center;
  min-height: 100vh;

  h1 {
    font-size: 6vh;

    @media screen and (max-width: $bp-medium) {
      font-size: 24px;
    }
  }
}

.SingleObject-floorplan {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  img {
    width: auto;
    height: calc(100vh - 100px);

    @media screen and (max-width: $bp-medium) {
      width: 350px;
      height: auto;
    }

    @media screen and (max-width: $bp-small) {
      width: 175px;
      height: auto;
    }

    @media screen and (max-width: $bp-xsmall) {
      width: 120px;
      height: auto;
    }
  }

  figcaption {
    margin-top: 10px;
    text-align: center;
  }
}

.SingleObject-main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  p,
  li {
    font-size: 3vh;

    @media screen and (max-width: $bp-medium) {
      font-size: 16px;
    }
  }

  a {
    color: $color-white;
  }
}

.SingleObject-summary {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
  max-width: 600px;
  list-style: none;

  & + p {
    margin-top: 20px;
  }
}
